import {
    useState,
    createContext,
    useContext,
    Fragment,
    useEffect,
} from "react";
import { Link } from "@inertiajs/react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

const DropDownContext = createContext();

const Dropdown = ({
    toggleAction = null,
    children,
    setActiveChannel,
    messages = false,
}) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((previousState) => !previousState);
        if (toggleAction !== null) {
            toggleAction();
        }
    };

    return (
        <DropDownContext.Provider
            value={{
                open,
                setOpen,
                toggleOpen,
                setActiveChannel,
                messages,
            }}
        >
            <div className="relative">{children}</div>
        </DropDownContext.Provider>
    );
};

const Trigger = ({ classes, children }) => {
    const { open, setOpen, toggleOpen, messages, setActiveChannel } =
        useContext(DropDownContext);
    const className = classNames(classes, {
        "btn-active": open,
        "bg-primary": open,
    });

    return (
        <>
            <div className={className} onClick={toggleOpen}>
                {children}
            </div>

            {open && (
                <div
                    className="fixed inset-0 z-40"
                    onClick={() => {
                        setOpen(false);
                        if (messages) {
                            setActiveChannel(null);
                        }
                    }}
                ></div>
            )}
        </>
    );
};

const Content = ({
    align = "right",
    width = "w-48",
    contentClasses = "py-1 bg-white",
    extraClasses = "",
    children,
}) => {
    const { open, setOpen } = useContext(DropDownContext);

    let alignmentClasses = "origin-top";

    if (align === "left") {
        alignmentClasses = "origin-top-left left-0";
    } else if (align === "right") {
        alignmentClasses = "origin-top-right right-0";
    }

    return (
        <>
            <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <ul
                    className={`absolute z-50 mt-3 p-2 menu menu-compact rounded-2xl shadow-2xl shadow-black bg-base-300 ${alignmentClasses} ${width} ${extraClasses}`}
                >
                    {children}
                </ul>
            </Transition>
        </>
    );
};

const DropdownDivider = () => {
    return <li></li>;
};

const DropdownCategory = ({ title }) => {
    return (
        <li className="menu-title">
            <span>{title}</span>
        </li>
    );
};

const DropdownLink = ({ href, onClick, method, as, children, className }) => {
    const { toggleOpen } = useContext(DropDownContext);

    const handleOnClick = () => {
        onClick();
        toggleOpen();
    };

    return (
        <li>
            {onClick ? (
                <button
                    className={`text-sm focus:outline-none focus:bg-primary ${className}`}
                    onClick={handleOnClick}
                >
                    {children}
                </button>
            ) : (
                <Link
                    href={href}
                    method={method}
                    as={as}
                    onClick={toggleOpen}
                    className={`flex w-full text-left text-sm focus:outline-none focus:bg-primary transition duration-150 ease-in-out ${className}`}
                >
                    {children}
                </Link>
            )}
        </li>
    );
};

Dropdown.Trigger = Trigger;
Dropdown.Content = Content;
Dropdown.Link = DropdownLink;
Dropdown.Divider = DropdownDivider;
Dropdown.Category = DropdownCategory;

export default Dropdown;
